import { render, staticRenderFns } from "./rask.vue?vue&type=template&id=0c4faf7a&scoped=true&"
import script from "./rask.vue?vue&type=script&lang=js&"
export * from "./rask.vue?vue&type=script&lang=js&"
import style0 from "./rask.vue?vue&type=style&index=0&id=0c4faf7a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c4faf7a",
  null
  
)

export default component.exports
<template>
  <div class="container">
    <div class="title">任务管理</div>
    <div class="add_task">
      <a-button style="width:104px;height:40px;" @click="raskAdd" type="primary"
        >新建任务</a-button
      >
      <div class="add_tips" v-if="addTaskTips === 0">
        <div class="tips_container">
          <div class="gif"><img src="../../assets/point.gif" alt="" /></div>
          <div class="tips_box">
            <div class="tips_text1">点击此处新建任务</div>
            <div class="tips_text2" @click="noTips">不再提示</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 搜索 -->
    <div class="search">
      <span>任务名称：</span>
      <a-input
        style="width:200px"
        v-model="queryParam.title"
        placeholder="请输入任务名称"
      />
      <div class="table-search-submitButtons">
        <a-button type="primary" @click="handleToSearchEnterprise"
          >查询</a-button
        >
        <a-button
          style="margin-left: 8px;"
          icon="reload"
          @click="resetSearchEnterprise"
          >重置</a-button
        >
      </div>
    </div>
    <!-- 标签页 -->
    <div class="tags">
      <s-table
        style="border-top:1px solid #F0F0F0;margin-top:16px"
        ref="raskTable"
        :columns="raskColumns"
        :data="raskData"
        :rowKey="(record) => record.id"
      >
        <span slot="status" slot-scope="text">
          <div class="table-point" v-if="text === '10'">
            <span></span> 待审核
          </div>
          <div class="table-point" v-if="text === '11'">
            <span></span> 审核失败
          </div>
          <div class="table-point" v-if="text === '13'">
            <span></span> 报名中
          </div>
          <div class="table-point" v-if="text === '14'">
            <span></span> 报名截止
          </div>
          <div class="table-point" v-else-if="text === '20'">
            <span></span> 进行中
          </div>
          <div class="table-point" v-else-if="text === '30'">
            <span style="background:#52C41A"></span> 已完成
          </div>
          <div class="table-point" v-else-if="text === '50'">
            <span style="background:#BFBFBF"></span> 已取消
          </div>
		  <div class="table-point" v-else-if="text === '60'">
		    <span style="background:#BFBFBF"></span> 审核中
		  </div>
		  <div class="table-point" v-else-if="text === '61'">
		    <span style="background:#BFBFBF"></span> 审核未通过
		  </div>
        </span>
        <span slot="action" slot-scope="text, record">
          <a
            @click="raskUpdate(record)"
            v-if="record.status === '61' && record.signNum === 0"
            >编辑</a
          >
          <a-divider
            type="vertical"
            v-if="record.status === '61' && record.signNum === 0"
          />
          <a
            @click="cancelRask(record)"
            v-if="record.status === '13' && record.signNum === 0"
            >取消任务</a
          >
          <a-divider
            type="vertical"
            v-if="record.status === '13' && record.signNum === 0"
          />
          <a @click="raskDetail(record)">详情</a>
        </span>
        <span slot="budgetAmount" slot-scope="text, record">
          <span v-if="record.budgetType == '10'">面议</span>
          <span v-if="record.budgetType == '20'"
            >{{ record.budgetStartAmount
            }}{{
              record.budgetEndAmount ? "-" + record.budgetEndAmount : ""
            }}</span
          >
        </span>
      </s-table>
    </div>
    <!-- 编辑 -->
    <a-modal
      title="编辑"
      :confirm-loading="confirmLoading"
      :visible="visibleDetail"
      @cancel="handleExamineModalCancel"
      @ok="handleRechargeModalOk"
      width="700px"
    >
      <a-form :form="examinForm" :hideRequiredMark="true">
        <a-row class="form-row">
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="任务名称"
              :labelCol="{ lg: { span: 4 }, sm: { span: 5 } }"
              :wrapperCol="{ lg: { span: 20 }, sm: { span: 19 } }"
            >
              <a-input
                placeholder="请输入任务名称(限30个字)"
                :maxLength="30"
                v-decorator="[
                  'title',
                  { rules: [{ required: true, message: '请输入任务名称!' }] },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="任务类别"
              :labelCol="{ lg: { span: 4 }, sm: { span: 5 } }"
              :wrapperCol="{ lg: { span: 20 }, sm: { span: 19 } }"
            >
              <a-cascader
                v-decorator="[
                  'taskTypes',
                  { rules: [{ required: true, message: '请选择任务类别!' }] },
                ]"
                :options="options"
                placeholder="请选择任务类别"
                @change="onChange"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              :labelCol="{ lg: { span: 4 }, sm: { span: 5 } }"
              :wrapperCol="{ lg: { span: 20 }, sm: { span: 19 } }"
              label="发票类目"
              v-if="ticketContentList.length > 0"
            >
              <div
                v-if="ticketContentList.length > 0"
                style="width:428px;line-height:24px;height:39px;display:flex;align-items:center;text-align:left"
              >
                <span v-for="(item, index) in ticketContentList" :key="index"
                  >{{ item.invoiceName }}
                  <span v-show="index < ticketContentList.length - 1">、</span>
                </span>
              </div>
            </a-form-item>
          </a-col>
          <!-- <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="报名时间" :labelCol="{lg: {span: 4}, sm: {span: 5}}" :wrapperCol="{lg: {span: 20}, sm: {span: 19} }">
              <a-range-picker @change="bmTime" v-decorator="['bmsj', {rules: [{ type: 'array', required: true, message: '请选择报名时间!' }]}]" />
            </a-form-item>
          </a-col> -->
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="任务起止日期"
              :labelCol="{ lg: { span: 4 }, sm: { span: 5 } }"
              :wrapperCol="{ lg: { span: 20 }, sm: { span: 19 } }"
            >
              <a-range-picker
                @change="rwTime"
                v-decorator="[
                  'rwsj',
                  {
                    rules: [
                      {
                        type: 'array',
                        required: true,
                        message: '请选择任务时间!',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="预计人数"
              :labelCol="{ lg: { span: 4 }, sm: { span: 5 } }"
              :wrapperCol="{ lg: { span: 20 }, sm: { span: 19 } }"
            >
              <a-input
                placeholder="请输入预计人数"
                suffix="人"
                min="1"
                type="number"
                v-decorator="[
                  'needNum',
                  { rules: [{ required: true, message: '请输入预计人数!' }] },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24">
            <div class="ysje_box">
              <div class="ysje_title">
                预算金额：<span v-if="record.budgetType == '10'">面议</span>
              </div>
              <div class="ysje" v-if="record.budgetType == '20'">
                <a-form-item>
                  <a-input
                    suffix="元"
                    :maxLength="11"
                    placeholder="起始金额"
                    v-decorator="[
                      'budgetStartAmount',
                      {
                        rules: [
                          { required: true, message: '请输入预算起始金额!' },
                          { validator: validateInputCode },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
                <div class="ysje_line">~</div>
                <a-form-item>
                  <a-input
                    suffix="元"
                    :maxLength="11"
                    placeholder="结束金额"
                    v-decorator="[
                      'budgetEndAmount',
                      {
                        rules: [
                          { required: true, message: '请输入预算结束金额!' },
                          { validator: validateInputCode },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
            </div>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="任务描述"
              :labelCol="{ lg: { span: 4 }, sm: { span: 5 } }"
              :wrapperCol="{ lg: { span: 20 }, sm: { span: 19 } }"
            >
              <a-textarea
                placeholder="请输入任务描述"
                style="width: 100%"
                v-decorator="[
                  'content',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入任务描述',
                        whitespace: true,
                      },
                    ],
                  },
                ]"
              >
              </a-textarea>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import STable from "@/components/table/";
import moment from "moment";
import {
  getRaskList,
  raskCancel,
  tastEdit,
  getRaskType,
  getInvoiceByIndustryId,
  getSysDictItem,
} from "@/api/api";
export default {
  components: {
    STable,
  },
  data() {
    return {
      queryParam: {
        enterpriseName: "",
      },
      raskColumns: [
        {
          title: "发布时间",
          dataIndex: "createTime",
          defaultSortOrder: "descend",
          sorter: (a, b) =>
            moment(a.createTime).unix() - moment(b.createTime).unix(),
        },
        {
          title: "任务名称",
          dataIndex: "title",
        },
        // {
        //   title: '任务单号',
        //   dataIndex: 'raskNumber',
        // },
        {
          title: "预算金额",
          dataIndex: "budgetAmount",
          scopedSlots: { customRender: "budgetAmount" },
        },
        {
          title: "预计人数",
          dataIndex: "needNum",
        },
        {
          title: "报名人数",
          dataIndex: "applyNum",
        },
        {
          title: "用工人数",
          dataIndex: "signNum",
        },
        {
          title: "任务状态",
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "结算类型",
          dataIndex: "payMode",
          customRender: (text) => (text === "10" ? "单次结算" : "多次结算"),
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      record: {},
      visibleDetail: false,
      examinForm: this.$form.createForm(this),
      params: {
        taskType: "",
        enrollBeginDate: "",
        enrollEndDate: "",
        workBeginDate: "",
        workEndDate: "",
      },
      options: [],
      ticketContentList: [],
      confirmLoading: false,
      addTaskTips: "",
      sensitiveWord: [],
    };
  },
  async created() {
    this.getRaskType();
    let pageTips = window.localStorage.getItem("allTipsFlag");
    if (!pageTips) {
    } else {
      this.addTaskTips = JSON.parse(pageTips).addTaskTips;
    }
    await getSysDictItem({ dictType: "task_sensitive_words" }).then((res) => {
      if (res.success) this.sensitiveWord = res.result;
    });
  },
  methods: {
    //不再提示
    noTips() {
      this.addTaskTips = 1;
      let allTips = window.localStorage.getItem("allTipsFlag");
      allTips = JSON.parse(allTips);
      allTips.addTaskTips = 1;
      console.log(allTips);
      window.localStorage.setItem("allTipsFlag", JSON.stringify(allTips));
    },
    // 获取任务类型
    getRaskType() {
      getRaskType().then((res) => {
        if (res.success) {
          this.options = res.result;
          this.options.forEach((item) => {
            item.label = item.industryName;
            item.value = item.id;
            if (item.children.length > 0) {
              item.children.forEach((list) => {
                list.label = list.industryName;
                list.value = list.id;
                list.children = "";
              });
            }
          });
          // console.log(this.options)
        }
      });
    },
    onChange(value) {
      console.log(value);
      this.params.taskType = value[1];
      getInvoiceByIndustryId(value[value.length - 1]).then((res) => {
        if (res.success) {
          this.ticketContentList = res.result;
        }
      });
    },
    // 报名时间
    bmTime(date, dateString) {
      this.params.enrollBeginDate = dateString[0];
      this.params.enrollEndDate = dateString[1];
    },
    // 任务时间
    rwTime(date, dateString) {
      this.params.workBeginDate = dateString[0];
      this.params.workEndDate = dateString[1];
    },
    // 编辑任务
    raskUpdate(record) {
      // console.log(record)
      this.params.enrollBeginDate = record.enrollBeginDate;
      this.params.enrollEndDate = record.enrollEndDate;
      this.params.workBeginDate = record.workBeginDate;
      this.params.workEndDate = record.workEndDate;
      this.params.taskType = record.taskType;
      this.visibleDetail = true;
      let currentTask = [];
      getInvoiceByIndustryId(record.taskType).then((res) => {
        if (res.success) {
          this.ticketContentList = res.result;
        }
      });
      this.options.forEach((item) => {
        if (item.children.length > 0) {
          item.children.forEach((list) => {
            if (record.taskType === list.value) {
              currentTask = [item.value, list.value];
            }
          });
        }
      });
      this.record = record;
      if (this.record.budgetType == "20") {
        setTimeout(() => {
          this.examinForm.setFieldsValue({
            title: record.title,
            needNum: record.needNum,
            budgetStartAmount: record.budgetStartAmount + "",
            budgetEndAmount: record.budgetEndAmount + "",
            content: record.content,
            taskTypes: currentTask,
            rwsj: [moment(record.workBeginDate), moment(record.workEndDate)],
            // bmsj:[moment(record.workBeginDate),moment(record.workEndDate)]
          });
        }, 100);
      } else {
        setTimeout(() => {
          this.examinForm.setFieldsValue({
            title: record.title,
            needNum: record.needNum,

            content: record.content,
            taskTypes: currentTask,
            rwsj: [moment(record.workBeginDate), moment(record.workEndDate)],
            // bmsj:[moment(record.workBeginDate),moment(record.workEndDate)]
          });
        }, 100);
      }
    },
    handleRechargeModalOk() {
      this.examinForm.validateFields((err, values) => {
        if (err) {
          return;
        }
        // 查询敏感词
        let result = ""; //敏感词匹配结果
        for (const element of this.sensitiveWord) {
          if (values.title.indexOf(element) > -1) {
            result = "任务名称内不能包含非法字符【" + element + "】";
            break;
          }
          if (values.content.indexOf(element) > -1) {
            result = '任务描述内不能包含非法字符【"' + element + "】";
            break;
          }
        }
        if (result) {
          this.$message.error(result);
          return;
        }
        this.confirmLoading = true;
        const description = this.examinForm.getFieldsValue();
        let params = Object.assign(description, this.params);
        // if (moment(params.workBeginDate).isBefore(params.enrollEndDate)) {
        //   this.$message.error('任务开始时间需大于报名结束时间')
        //   return
        // }
        // params.budgetAmount = Number(params.budgetAmount).toFixed(2)
        tastEdit(Object.assign(params, { id: this.record.id })).then((res) => {
          this.confirmLoading = false;
          if (res.success) {
            this.$message.success(res.message);
            this.handleExamineModalCancel();
            this.handleToSearchEnterprise();
          } else {
            this.$message.error(res.message);
          }
        });
      });
    },
    handleExamineModalCancel() {
      this.examinForm.resetFields();
      this.visibleDetail = false;
    },
    // 获取任务列表
    raskData(parameter) {
      return getRaskList(Object.assign(parameter, this.queryParam)).then(
        (res) => {
          return res.result;
        }
      );
    },
    // 新增任务
    raskAdd() {
      window.localStorage.setItem("submenu", "/raskAdd");
      this.$router.push("/raskAdd");
    },
    // 查询
    handleToSearchEnterprise() {
      this.$refs.raskTable.refresh(true);
    },
    // 重置
    resetSearchEnterprise() {
      this.queryParam = {
        title: "",
      };
      this.handleToSearchEnterprise();
    },
    // 选择标签
    callback(key) {
      console.log(key);
    },
    // 表格改变
    onRaskChange(pagination, filters, sorter) {
      // console.log('params', pagination, filters, sorter)
    },
    // 取消任务
    cancelRask(record) {
      let that = this;
      this.$confirm({
        title: "确定取消该任务?",
        // content: h => <div style="color:red;">Some descriptions</div>,
        onOk() {
          return new Promise((resolve, reject) => {
            raskCancel(record.id).then((res) => {
              // console.log(res)
              if (res.success) {
                that.$message.success(res.message);
                that.handleToSearchEnterprise();
                resolve();
              } else {
                that.$message.error(res.message);
                reject();
              }
            });
          }).catch(() => console.log("Oops errors!"));
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    raskDetail(record) {
      window.localStorage.setItem("raskId", record.id);
      if (record.payMode === "10") {
        window.localStorage.setItem("submenu", "/raskDetailNew");
        this.$router.push({
          name: "raskDetailNew",
          query: { id: record.id },
          params: { id: record.id },
        });
      }
      if (record.payMode === "20") {
        window.localStorage.setItem("submenu", "/raskDetailManyNew");
        this.$router.push({
          name: "raskDetailManyNew",
          query: { id: record.id },
          params: { id: record.id },
        });
      }
      // window.localStorage.setItem('submenu','/raskDetailNew')
      // this.$router.push({name: 'raskDetailNew',query:{id:record.id},params:{id:record.id}})
    },
  },
};
</script>
<style lang="less" scoped>
.ysje_line {
  // height: 40px;

  padding: 0 10px 24px 10px;
  color: rgba(0, 0, 0, 0.85);
}
.ysje_title {
  // height: 40px;
  padding-bottom: 24px;
  color: rgba(0, 0, 0, 0.85);
  padding-left: 40px;
}
.ysje_box {
  display: flex;
  align-items: center;
  height: 64px;
}
.ysje {
  display: flex;
  align-items: center;
}
.add_task {
  position: relative;
  .add_tips {
    position: absolute;
    left: 150px;
    top: -10px;
    .tips_container {
      .gif {
        position: absolute;
        left: -40px;
        top: 10px;
      }
      .tips_box {
        padding: 8px;
        background: #1890ff;
        border-radius: 5px;
        color: #fff;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          left: -9px;
          top: 9px;
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-right: 10px solid #1890ff;
          border-bottom: 10px solid transparent;
        }

        .tips_text1,
        .tips_text2 {
          font-size: 12px;
        }
        .tips_text2 {
          text-align: right;
          padding-top: 10px;
          cursor: pointer;
        }
      }
    }
  }
}
.title {
  font-size: 24px;
  color: #202020;
  margin-bottom: 26px;
}
.search {
  margin-top: 16px;
  display: flex;
  align-items: center;
}
.table-search-submitButtons {
  display: flex;
  align-items: center;
  height: 40px;
  margin-left: 30px;
}
.tags {
  margin-top: 20px;
}
.content {
  margin-top: 16px;
}
.table-point {
  display: flex;
  align-items: center;
  // justify-content: center;
  span {
    width: 4px;
    height: 4px;
    background: #1890ff;
    border-radius: 50%;
    margin-right: 4px;
  }
}
</style>
